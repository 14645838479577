@import 'helpers/_variables';

.loginContainer {
  .description {
    font-size: 12px;
    color: $text-color;
    padding: 4px 2px 8px 2px;
  }
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.code {
  width: 33%;
}

.phone {
  width: calc(67% - 16px);
}

.seperater {
  height: 1px;
  background: #e7e7e7;
  margin: 36px auto;
  width: 100%;
  position: relative;
  margin: 24px 0;
}

.seperater::after {
  content: 'or';
  color: #c2c2c2;
  background: #fffbfa;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  top: 0;
  left: 50%;
  margin-top: -12px;
  margin-left: -10px;
  border-radius: 50%;
}

.error {
  color: red;
  font-size: 14px;
}

.submitBtn {
  margin-top: 16px;
}

.googleBtn {
  background-color: white;
  box-shadow: 0 3px 6px rgba(black, 0.1);
  margin: auto;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  padding: 12px 16px;

  img {
    width: 20px;
  }

  span {
    color: black;
    font-weight: 600;
    font-size: 16px;
  }
}

@media only screen and (max-width:767px) {

  .code,
  .phone {
    width: 100%;
  }

  .googleBtn {
    width: 100%;
  }
}