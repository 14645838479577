@import "helpers/_variables";

.booking-confirmation-details {
  margin: 24px 40px;

  .form-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .hotel-detail-enquiry {
    padding-top: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    margin-left: 50px;
    margin-top: -20px;
  }

  .description {
    font-size: 12px;
    color: $text-color;
    margin: 24px 0px;
  }
}

.hotel-detail-enquiry-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  //   z-index: 2;
  background-color: white;
}

@media only screen and (max-width: $desktop) {
  .guest-form-fields-booking {
    margin-top: -50px;

    .confirm-pay {
      font-size: 34px;
      font-weight: bold;
    }

    .booking-confirmation-details {
      margin: 20px 0;

      .form-heading {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .guest-form-fields-booking {
    margin-top: 0px;

    .confirm-pay {
      font-size: 24px;
      font-weight: bold;
    }

    .booking-confirmation-details {
      margin: 20px 0;

      .form-heading {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
}