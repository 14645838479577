.hotel-details-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(-100%);
  }

  &.visible {
    transform: translateY(0);
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 16px 20px;
  height: 70px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
  }
}

.nav-links {
  display: flex;
  list-style-type: none;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

.nav-links li {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
}

.reserve-container {
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}

.price-review {
  display: flex;
  flex-direction: column;
  line-height: 1rem;
}

.reviews {
  font-weight: 500;
  text-decoration: underline;
  font-size: small;

  &:hover {
    cursor: pointer;
  }
}
.overallReviews {
  font-size: 12px;
  font-weight: 600;
  color: black;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.price {
  font-size: 16px;
  font-weight: bold;
  color: #333;

  span {
    font-size: 12px;
    font-weight: normal;
    color: #666;
  }
}

.reserve-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0314a;
  }

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    text-align: center;
  }
}

@media (max-width: 480px) {
  .nav-links li {
    font-size: 14px;
    padding: 6px 10px;
  }

  .price {
    font-size: 16px;
  }

  .reserve-button {
    font-size: 14px;
    padding: 10px;
  }
}
