@import 'helpers/_variables';

.myBookingContainer {
  padding: 100px 16px 48px;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  color: $heading-color;
}

.tabListWrapper {
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin: 16px 0 32px;
  border-bottom: $divider-color;
}

.tabListWrapper::-webkit-scrollbar {
  height: 0;
}

.tabWrapper {
  display: flex;
  height: 42px;
  gap: 24px;
}

.tab {
  position: relative;
  font-size: 16px;
  cursor: pointer;
  color: $text-color;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.tab.active {
  color: rgb(34, 34, 34);
  animation-name: box;
  animation-duration: 1s;
}

.tabName {
  background-color: white;
  padding: 6px 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  cursor: pointer;
}

.tabName:hover {
  background-color: $hover-color;
}

.tabBorder {
  background-color: rgb(34, 34, 34);
  height: 2px;
  animation-name: box-line;
  animation-duration: 1s;
}

.bookingList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bookingListItem {
  padding: 16px 32px;
  border: 1px solid rgb(238, 237, 237);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.bookingListItem:hover {
  background-color: rgb(238, 237, 237);
}

.bookingItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bookingLabel {
  font-size: 14px;
  color: $text-color;
}

.bookingId {
  color: $primary-color;
}

.bookingId:hover {
  text-decoration: underline;
}

.bookingImageStyle {
  height: 60%;
  width: 60%;
  display: block;
  margin: auto;
  opacity: 0.8;
}

@media only screen and (max-width: $desktop) {
  .tabWrapper {
    gap: 16px;
  }

  .bookingListItem {
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    border: 1px solid lightgray;
  }

  .bookingItem {
    flex-direction: row;
    justify-content: space-between;
  }

  .bookingId {
    text-decoration: underline;
  }
}