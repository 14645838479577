@import "helpers/_variables";

.host-details-container {
  .image {
    width: 60px;
    height: 60px;
    padding-top: 2px;
    border-radius: 100%;
    background-color: lightgray;
  }

  & h4 {
    font-weight: normal;
    font-size: 22px;
    font-weight: bold;
  }

  & h5 {
    color: $text-color;
    font-size: 16px;
  }
}
