.footer-page-style {
  padding-top: 100px;

  .footer-page-container {
    width: 88%;
    margin: 0 auto;
    padding: 0px 0px;
    padding-bottom: 60px;

    .heading {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 6px;
    }

    .image-style {
      width: 100%;
      border-radius: 8px;
    }
  }
}