// Image Gallery
.image-gallery {
  margin: 0 -8px;
}

.image-gallery-left-nav {
  top: 50%;
  position: absolute;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 8px;
}

.image-gallery-right-nav {
  right: 0;
  top: 50%;
  position: absolute;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 8px;
}

// Image Grid Display
.image-container {
  width: 100%;
  margin-bottom: 20px;

  .show-more {
    font-size: 14px;
    background-color: black;
    opacity: 0.9;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    float: right;
    margin-right: 20px;
    margin-top: -50px;
    cursor: pointer;
  }
}

.image-grid-container {
  display: grid;
  grid-template-rows: 180px 180px;
  grid-template-columns: 47% 25% 25%;
  grid-gap: 12px;
}

.image-grid-container > :first-child {
  grid-row: 1/3;
}

.image-grid-container > img {
  height: 100%;
  width: 100%;
  // border-radius: 8px;
}

.image-grid-container > img:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.image-grid-container > img:nth-child(3) {
  border-top-right-radius: 8px;
}

.image-grid-container > img:nth-child(5) {
  border-bottom-right-radius: 8px;
}

// Image Display Section
.modal-overlay {
  position: fixed;
  z-index: 2000;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  .image-box {
    position: relative; /* Ensures .count positions correctly inside */
    max-width: 820px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .count {
    position: absolute;
    top: 20px;
    left: 16px;
    margin: 0;
    text-align: left;
    margin-bottom: 0;
    font-weight: bold;
    color: rgb(255, 255, 255);
    padding: 2px;
    border-radius: 2px;
    background-color: rgb(166, 164, 163);
  }

  .image-style {
    margin-bottom: 20px;
    width: 100%;
    // min-height: 400px;
    max-height: 650px;
  }
}

.left-btn {
  top: 45%;
  left: 0;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 24px;
  cursor: pointer;
}

.right-btn {
  right: 0;
  top: 45%;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 24px;
  cursor: pointer;
}

.close-btn {
  right: 0;
  top: 30px;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 1rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 10px;
}

.close-btn:hover {
  background-color: rgba(104, 102, 102, 0.7);
  padding: 10px 15px;
  border-radius: 10px;
}
