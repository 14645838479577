.room-policies {
  margin-top: 20px;
  .room-policies__card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .room-policies__section {
    padding: 20px;
  }

  .room-policies__description {
    font-weight: 400;
    font-size: 15px;
    color: #333;
  }

  .verticallyHroziontal {
    margin: 20px 0;
  }

  /* Large screens */
  @media (max-width: 1200px) {
    .room-policies__card {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  /* Mobile screens */
  @media (max-width: 768px) {
    .room-policies__card {
      grid-template-columns: 1fr; /* 1 column on smaller screens */
    }
  }
}

.policy-list {
  list-style: none; // Remove bullets
  padding: 0;
  margin: 0;

  .policy-item {
    padding: 8px 0;
    font-size: 14px;
    color: #333;

    &.active-text {
      font-weight: 400;
    }
  }
}

.show-more-btn {
  display: inline-block;
  margin-top: 10px;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
}

.cancellation-modal {
  .policy-info {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #8f8f8f;
  }

  .policy-section {
    .cancellation-title {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .cancellation-row {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
      width: 100%;
    }

    .days-description {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }

    .refund-description {
      flex: 2;
      font-size: 16px;
      font-weight: 400;
      color: #333;
    }

    .divider {
      border: none;
      border-top: 1px solid #dedede;
      margin: 10px 0;
      width: 100%;
    }
  }
}

.show-more {
  display: inline-flex;
  align-items: center;
}

.show-more-text {
  text-decoration: underline;
}

.greater-than {
  margin-left: 5px;
  text-decoration: none;
}

// .safety-property-modal {
//   padding: 20px;
//   font-family: Arial, sans-serif;
// }

// .info-text {
//   margin-bottom: 20px;
//   font-size: 16px;
//   color: #6b6b6b;
// }

// .safety-section {
//   margin-top: 20px;
// }

// .section-title {
//   font-weight: bold;
//   font-size: 18px;
//   color: #333;
//   margin-bottom: 10px;
// }

// .device-details {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 15px;
// }

// .device-name {
//   font-weight: bold;
//   font-size: 14px;
//   color: #333;
// }

// .device-suggestion {
//   font-size: 14px;
//   color: #6b6b6b;
//   margin-top: 5px;
// }

// .divider {
//   border: none;
//   border-top: 1px solid #ddd;
//   margin: 15px 0;
// }
