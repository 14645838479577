.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // Full screen height
  text-align: center;
  color: #dc2626; // Tailwind's `text-red-600` color
  background-color: #dadada; // Tailwind's `bg-gray-100` color

  h2 {
    font-size: 1.5rem; // Tailwind's `text-2xl`
    font-weight: bold;
    margin-bottom: 0.5rem; // Tailwind's `mb-2`
  }

  p {
    font-size: 1.125rem; // Tailwind's `text-lg`
  }
}
