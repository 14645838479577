.section {
  padding: 0 90px;
  margin: 70px 0px;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    margin: 40px 0px;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 20px;
    margin: 40px 0px;
  }

  .title-section {
    margin-bottom: 2rem;

    h3 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-family: "Josefin Sans", sans-serif;
    }

    p {
      font-size: 1.125rem;
      color: #6b7280;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      line-height: 27px;
    }
  }

  .desktop-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1.5fr) minmax(0, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: column;
    grid-column-gap: 8.7%;
    row-gap: 24px;
    margin-bottom: 40px;

    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(3, auto);
      grid-auto-flow: row;
      align-items: center;
      column-gap: 24px;
      margin-bottom: 32px;
      row-gap: 2px;
    }

    .group {
      display: flex;
      flex-direction: column;

      .image-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 8px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .center-img {
        border-top-right-radius: 80px;
        flex: 1 1;
        position: relative;
        width: 100%;
        overflow: hidden;
        height: auto; /* Allow the height to be based on the content */

        @media only screen and (max-width: 576px) {
          max-height: 310; /* Limit the max height on small screens */
          object-fit: cover; /* Ensure the image covers the container */
        }
      }
    }

    .center {
      grid-row: span 2;
      @media only screen and (max-width: 576px) {
        grid-column: 1 / -1;
        max-height: 310px;
      }
    }

    // .side-image-container {
    //   aspect-ratio: 5 / 2.9;
    // }
  }

  .city-info {
    display: flex;

    .icon {
      color: #ef4444;
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }

    .city-name {
      font-weight: 400;
      color: #2a2a2a;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
    }
  }

  .hotel-count {
    color: #980101;
    font-size: 0.875rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: left;
    margin-bottom: 4px;
  }
}
