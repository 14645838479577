@import "../../helpers/variables";

.hotel-listing-container {
  padding-top: 90px;

  .hotel-listing-page {
    width: 88%;
    min-height: 42vh;
    margin: 0 auto;
    padding-bottom: 4rem;

    .count {
      color: $heading-color;
      // font-size: 0.875rem;
    }

    .heading {
      font-weight: 700;
    }
  }
}

// Hotel Item Style ------------------------------------------------------
.hotel-item-card {
  width: 100%;
  padding: 8px;
  height: 400px;
  margin: 12px 0px;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid rgb(219, 217, 217);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    border: 1px solid white;
    box-shadow: 0px 0px 4px 0px rgb(160, 158, 158);
  }

  .description-container {
    width: 100%;
    padding: 0px 6px;
  }

  .hotel-item-rating {
    height: 34px;
    font-size: 16px;
    font-weight: bold;
  }

  .hotel-item-rating .users {
    font-weight: normal;
    color: $text-color;
    margin-left: 4px;
  }

  .hotel-item-rating .star {
    font-weight: normal;
    color: $primary-color;
    font-size: 20px;
    margin-right: 4px;
  }

  .hotel-item-price {
    font-weight: bold;
    font-size: 20px;
  }

  .hotel-item-type {
    font-size: 14px;
    color: $text-color;
    margin: 6px 0px;
  }

  .hotel-item-name {
    font-size: 18px;
    color: $text-color;
    margin-bottom: 4px;
    height: 24px;
    overflow: hidden;
  }

  .hotel-item-rooms-type {
    font-size: 14px;
    color: $text-color;
    height: 24px;
  }

  .hotel-item-amenities {
    font-size: 14px;
    color: $text-color;
    height: 20px;
  }

  .image {
    height: 200px;
    width: 300px;
    width: 100%;
    border-radius: 8px;
  }

  .hotel-item-rating-price {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .hotel-item-price .per-night {
    font-weight: normal;
    font-size: 18px;
  }
}
