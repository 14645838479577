@import "helpers/_variables";

.bottom-navigation-menu {
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 8px 0px rgb(224, 209, 209);
  z-index: 200;

  .bottom-navigations {
    display: flex;
    justify-content: center;
    gap: 48px;
    margin: 8px 0;
  }

  .navigation-item {
    text-align: center;
    margin: 0px;
  }

  .navigation-item.active svg,
  .navigation-item.active .item {
    color: $primary-color;
  }

  .icon {
    margin-bottom: 2px;
    color: $text-color;
  }

  .item {
    font-size: 12px;
  }
}

.userImg {
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
  border-radius: 50%;
}