@import 'helpers/_variables';

.shareBtn {
  font-weight: bold;
  cursor: pointer;
}

.modal {
  max-width: 600px;
}

.detailWrapper {
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
}

.image {
  border-radius: 8px;
  width: 100px;
  height: auto;
}

.desc {
  color: $text-color;
  font-weight: 500;
  font-size: 13px;
}

.title {
  display: block;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
  color: $heading-color;
}

.btnWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.btn {
  width: calc(50% - 12px);
  outline: 0;
  background-color: white;
  border: 1px solid rgb(34, 34, 34);
  border-radius: 8px;
  padding: 16px 24px;
  text-align: left;
  font-weight: 500;
}

.icon {
  padding: 0;
  font-size: 20px;
}

.fb .icon {
  color: #3b5998;
}

.twitter .icon {
  color: #00acee;
}

.whatsApp .icon {
  color: #2FB542;
}

.copy .icon {
  color: lightgray;
}

.email .icon {
  color: #ccc;
}