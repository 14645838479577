.form-field {
    border: 1px solid gray;
    border-radius: 8px;
    padding: 4px;
    height: 60px;
    margin: 8px 0px 2px 0px;

    .form-field-label {
        font-size: 12px;
        color: gray;
        padding: 4px 8px;
    }

    .form-field-input {
        border: none;
        width: 100%;
        font-size: 16px;
        padding: 0px 8px;
        outline: none
    }
}

.error-message {
    font-size: 12px;
    padding: 0px 12px;
    color: red;
}

.form-field:focus {
    border: 1px solid black;
}
