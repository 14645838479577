@import "helpers/_variables";

.header-section-container {
  width: 100%;
  z-index: 5;
  color: white;
  position: fixed;
  background-color: transparent;
  top: 0;

  .header-section-style {
    padding: 12px 24px;
  }

  .header-section-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo {
    height: 32px;
    width: 135px;
  }

  .black-logo {
    color: white;
  }

  .host-link {
    padding: 12px 14px;
    cursor: pointer;
    font-size: 16px;
  }

  .host-link:hover {
    background-color: rgba($color: white, $alpha: 0.2);
    border-radius: 24px;
  }

  .active-link:hover {
    background-color: rgba($color: black, $alpha: 0.03);
  }

  .places-to-stay {
    padding-bottom: 8px;
    cursor: pointer;
  }

  .places-to-stay::after {
    content: "";
    position: absolute;
    background-color: white;
    height: 2px;
    width: 20px;
    top: 30px;
    left: 55px;
  }

  .modal-style {
    width: 586px;
  }

  .logo-style {
    cursor: pointer;
    height: 45px;
    width: auto;
  }

  .white-logo-style {
    cursor: pointer;
    height: 45px;
    width: auto;
  }
}

.header-section-container.active {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px;
  background-color: white;
  color: black;
}

.call-support {
  flex-direction: row;
}
