.newsletter-container {
  background-color: #f7fafc;
  padding: 3rem 1.5rem;

  @media (min-width: 1024px) {
    padding: 5rem 5.625rem;
  }

  .newsletter-content {
    max-width: 90rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .newsletter-text {
    margin-bottom: 1.5rem;

    @media (min-width: 1024px) {
      margin-bottom: 0;
    }

    .newsletter-subtitle {
      font-size: 1.25rem;
      color: #5a5a5a;
      margin-bottom: 1rem;
      font-family: "Josefin Sans", sans-serif;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }

      @media (min-width: 1024px) {
        font-size: 1.75rem;
      }
    }

    .newsletter-title {
      font-size: 1.75rem;
      font-weight: 500;
      color: #5a5a5a;
      margin-bottom: 0.5rem;
      font-family: "Josefin Sans", sans-serif;
      line-height: 2rem;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3rem;
      }

      @media (min-width: 1024px) {
        font-size: 2.5rem;
        line-height: 3rem;
        padding-right: 20px;
      }
    }
  }

  .newsletter-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
      width: auto;
    }

    .newsletter-input {
      padding: 0.75rem 1rem;
      border: none;
      border-bottom: 2px solid #d1d5db;
      width: 100%;
      background-color: transparent;
      font-size: 1rem;
      margin-bottom: 1rem;
      margin-right: 0;

      @media (min-width: 768px) {
        font-size: 1.125rem;
      }

      @media (min-width: 1024px) {
        margin-bottom: 0;
        margin-right: 0.625rem;
        width: 24rem;
      }

      &:focus {
        outline: none;
        border-bottom-color: #9ca3af;
      }
    }

    .newsletter-button {
      padding: 0.75rem 2rem;
      background-color: transparent;
      color: #bf0101;
      font-weight: 600;
      border: 1px solid #bf0101;
      border-radius: 0.375rem;
      transition: background-color 0.3s ease, color 0.3s ease;
      width: 100%;

      @media (min-width: 1024px) {
        width: auto;
      }

      &:hover {
        background-color: #bf0101;
        color: #fff;
      }
    }
  }
}

.loading-text {
  color: #4b5563;
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
}
