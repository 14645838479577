@import 'helpers/_variables';

.bookingDetailsContainer {
  padding: 0;
}

.heading {
  font-size: 24px;
  padding-top: 16px;
  font-weight: bold;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 96px;
  margin-bottom: -48px;
  padding: 0 48px;
}

.downloadBtn {
  cursor: pointer;
}

.detailsContainer {
  padding: 0 48px 48px;
  margin-top: -48px;
}

.detailsHeading {
  font-size: 16px;
  font-weight: 600;
  color: $text-color;
  margin-bottom: 0;
}

.detailsValue {
  font-size: 14px;
  color: $text-color;
}

.columnWrapper {
  margin-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (max-width: $desktop) {
  .bookingDetailsContainer {
    padding: 48px 0px;
    margin: 0px;
  }

  .heading {
    font-size: px;
    padding-top: 18px;
    font-weight: bold;
  }

  .detailsHeading {
    font-size: 14px;
    color: $text-color;
    margin-bottom: 4px;
  }

  .columnWrapper {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px;
  }

}