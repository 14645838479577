@import "helpers/_variables";

.enquiry-form-container {
  margin: 24px 0px;
  box-sizing: border-box;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 14px 0px lightgray;
  padding: 24px 16px;
  width: 100%;
  max-width: 390px;
  border-radius: 8px;

  .hotel-item-rating-price {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 8px;
  }

  .hotel-item-price {
    font-weight: bold;
    font-size: 22px;
  }

  .hotel-item-price .per-night {
    font-weight: normal;
    font-size: 18px;
  }

  .hotel-item-rating {
    font-size: 15px;
    font-weight: bold;
  }

  .hotel-item-rating .users {
    font-weight: normal;
  }

  .hotel-item-rating .star {
    font-weight: normal;
    color: $primary-color;
    font-size: 20px;
    margin-right: 4px;
  }

  .hotel-item-rating .users {
    font-weight: normal;
    margin-left: 4px;
  }

  .hotel-item-type-icon {
    display: flex;
    justify-content: space-between;
  }

  // Guests form

  .guests-type-container {
    background-color: white;
    position: absolute;
    margin-top: 0px;
    width: 100%;
    position: inherit;
    margin-left: 0px;
    // border-radius: 40px;
    max-width: 350px;
    min-height: 80px;
    // overflow: auto;
    padding: 16px 24px;
    -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);

    .guests-type-container.noselect {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
  }

  .enquiry-guests-field {
    .guests-type-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #dddddd;
    }

    .guests-type-row:last-child {
      border-bottom: none;
    }

    .heading {
      font-size: 16px;
      font-weight: bold;
      word-wrap: break-word;
    }

    .description {
      font-size: 14px;
    }

    .modal-close-icon {
      text-align: center;
      cursor: pointer;
    }

    .guests-type-btn-box {
      display: flex;
      align-items: center;
    }

    .minus {
      cursor: pointer;
    }

    .count {
      padding: 0px 16px;
      font-size: 16px;
      width: 50px;
      text-align: center;
      box-sizing: border-box;
    }

    .plus {
      cursor: pointer;
    }
  }
}

.billing-information-container {
  margin-top: 10px;

  .type {
    font-size: 16px;
  }

  .type-bold {
    font-size: 18px;
    font-weight: bold;
  }

  .amount-bold {
    font-size: 18px;
    font-weight: bold;
  }
}

.notAvailable {
  padding: 24px 0;
  text-align: center;
}

.flatLink {
  color: $primary-color;
  cursor: pointer;
}

.flatLink:hover {
  text-decoration: underline;
}