.circularProgressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    transform: rotate(-90deg); // Rotates the circle to start from the top
  }

  .progressText {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}
