@import 'helpers/_variables';

.payement-details-page {
    padding-top: 50px;
}

.payment-details-container {
    padding: 20px 48px 48px;
    margin-left: 20px;

    .booking-details-heading {
        font-size: 24px;
        padding-top: 16px;
        font-weight: bold;
        color: $heading-color
    }

    .details-heading {
        font-size: 14px;
        color: $heading-color;
        margin-bottom: 4px;
    }

    .column-wrapper {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .image-style {
        width: 80%;
    }
}


@media only screen and (max-width: $desktop) {
    .payment-details-container {
        padding: 48px 0px;
        margin: 0px;

        .booking-details-heading {
            font-size: px;
            padding-top: 18px;
            font-weight: bold;
            color: rgb(43, 42, 42);
            ;
        }

        .details-heading {
            font-size: 14px;
            color: gray;
            margin-bottom: 4px;
        }

        .column-wrapper {
            margin-top: 8px;
            margin-bottom: 8px;
            padding: 0px;
        }
    }
}