@import "helpers/_variables";

.image-slider {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: gray;

  // Slider images
  .slider-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 1s ease-in-out;

    .slide {
      min-width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: opacity 1s ease-in-out;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }

  // Slider content
  .slider-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(11, 7, 7);
    text-align: center;
    z-index: 2;

    .gradient {
      background: rgb(129, 129, 129);
      background: radial-gradient(
        circle,
        rgba(129, 129, 129, 0.57) 25%,
        rgba(148, 187, 233, 0) 100%
      );
    }

    h5 {
      font-family: "Josefin Sans", sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: #fffbfb;
      border-radius: 8px;

      @media (max-width: 768px) {
        font-size: 20px;
        color: #333;
      }

      @media (max-width: 480px) {
        font-size: 18px;
        color: #333;
      }
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
      font-family: "Josefin Sans", sans-serif;
      color: #fffcfc;
      border-radius: 8px;

      @media (max-width: 768px) {
        font-size: 2rem;
        color: #333;
      }

      @media (max-width: 480px) {
        font-size: 1.5rem;
        color: #333;
      }
    }

    .search-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      input {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        border: none;
        border-radius: 5px;
        margin-right: 1rem;
        width: 100%;
        max-width: 300px;

        @media (max-width: 768px) {
          width: 80%;
        }

        @media (max-width: 480px) {
          width: 90%;
        }
      }

      button {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        background-color: #ff6347;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 1rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }

        @media (max-width: 480px) {
          font-size: 0.9rem;
        }
      }
    }

    @media (max-width: 992px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      height: auto;
      margin-top: 122px;
      padding-top: 32px;

      .gradient {
        background: none;
      }
    }
  }

  // Slider dots
  .slider-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.active {
        background-color: #fff;
      }

      @media (max-width: 768px) {
        width: 10px;
        height: 10px;
      }

      @media (max-width: 480px) {
        width: 8px;
        height: 8px;
      }
    }
  }

  // Mobile view layout
  @media (max-width: 768px) {
    height: auto;
    display: flex;
    flex-direction: column;

    .slider-images {
      position: relative;
      width: 100%;
      height: 240px;
    }

    .slider-content {
      position: relative;
      top: 0;
      transform: none;
      margin-top: -20px;
      padding: 20px;
      background-color: white;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }

  @media (max-width: 480px) {
    .slider-images {
      height: 350px;
    }
  }
}

// Home Page Banner Style ---------------------------------------------
.home-page-banner {
  height: 86vh;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: background-image 1s ease-in-out;

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .animation-text {
    width: 100%;
    color: white;
    line-height: 40px;
    font-weight: bold;
    padding: 0 24px;
    padding-top: 12rem;
    text-align: center;
  }

  .dots-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }

    &.active {
      background-color: white;
    }
  }
}

// Property List Style------------------------------------------------------
.property-list-container {
  width: 88%;
  margin: 1.9rem auto;
  overflow-x: hidden;

  .container {
    width: 150px;
    height: 1px;
    background-color: $primary-color;
    position: relative;
    margin: 16px auto;
    margin-bottom: 32px;
  }

  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    background: $primary-color;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 100%;
    top: -4px;
  }

  .heading {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0px 24px;
    text-align: center;
  }

  .list-container {
    overflow-x: auto;
    display: flex;
    margin: 20px 0px;
    cursor: pointer;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-container::-webkit-scrollbar {
    // display: none !important;
    width: 10px;
    height: 4px;
  }

  /* Track */
  .list-container::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  .list-container::-webkit-scrollbar-thumb {
    background: tomato;
    border-radius: 10px;
  }

  .property-list-card {
    margin-right: 20px;
  }

  .property-list-card-2 {
    background-image: url("https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/bologna-3.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    height: 12.5rem;
    width: 17.5rem;
  }

  .card-image-overlay {
    background-color: rgba(#000, 0.6);
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .card-image-overlay div {
    color: white;
    font-weight: bold;
    font-size: 1.9rem;
    margin: 0 auto;
    text-align: center;
  }

  .property-list-image {
    border-radius: 12px;
    height: 12.5rem;
    width: 17.5rem;
  }

  .property-list-name {
    font-size: 1rem;
    text-align: center;
    color: $text-color;
    margin: 8px 0px;
  }
}

// Nearby Locations Style----------------------------------------------------
.nearby-container {
  width: 88%;
  margin: 70px auto;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.nearby-list {
  display: grid;
  grid-template-rows: 72px 72px;
  grid-template-columns: repeat(4, 18rem);
  grid-gap: 16px;

  .nearby-card {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .nearby-card img {
    border-radius: 12px;
    height: 72px;
    width: 72px;
    cursor: pointer;
  }

  .description {
    padding: 0px 16px;
    cursor: pointer;
  }
}

.nearby-container::-webkit-scrollbar {
  display: none !important;
}

// Host Feature Style --------------------------------------------------------
.host-feature-container {
  width: 88%;
  margin: 2rem auto;
  overflow-x: hidden;

  .heading {
    font-size: 2rem;
    font-weight: 600;
  }

  .list-container {
    overflow-x: scroll;
    display: flex;
    margin: 24px 0px;
    cursor: pointer;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-container::-webkit-scrollbar {
    display: none !important;
  }

  .host-feature-card {
    margin-right: 20px;
    font-size: 1.2rem;
  }

  .host-feature-card iframe {
    border: none;
    outline: none;
    height: 256px;
    width: 384px;
    border-radius: 12px;
  }
}

// Future Gateways Style ---------------------------------------------------
.future-gateways {
  width: 88%;
  margin: 2rem auto;
  overflow-x: hidden;

  .heading {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0px 24px;
    text-align: center;
  }

  // Future Gateways
  .future-gateways-style {
    padding: 12px 25px 48px;
  }

  // Future Gateways Headings
  .future-gateways-heading-row {
    display: flex;
    overflow-y: scroll;
    border-bottom: $divider-color;
    height: 43px;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  .heading-container {
    font-size: 16px;
    margin-right: 16px;
    cursor: pointer;
  }

  @keyframes box {
    0% {
      transform: scale(0.95, 0.95);
    }

    50% {
      transform: scale(1, 1);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .active {
    color: rgb(34, 34, 34);
    animation-name: box;
    animation-duration: 1s;
  }

  .header-name {
    background-color: white;
    padding: 6px 8px;
    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .header-name:hover {
    background-color: $hover-color;
    padding: 6px 8px;
    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .header-border {
    background-color: rgb(34, 34, 34);
    height: 2px;
    width: 95%;
    margin: auto;
    animation-name: box-line;
    animation-duration: 1s;
  }

  // Future Gateways Links
  .future-gateways-link {
    margin: 16px 0px;
    cursor: pointer;
  }

  .gateways-link-1 {
    font-size: 15px;
    color: $text-color;
  }

  .gateways-link-2 {
    font-size: 15px;
    color: $text-color;
  }
}
