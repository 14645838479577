.reviewerWrapper {
  display: flex;
  // align-items: center;
  gap: 16px;
  font-size: 16px;
}

.img {
  height: 44px;
  border-radius: 50%;
  pointer-events: none;
}

.date {
  font-size: 14px;
  color: #717171;
}

.comment {
  font-size: 16px;
  // margin-top: 8px;
  // width: max-content;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 72px;
}

.comment.autoHeight {
  max-height: none;
  overflow: visible;
  -webkit-line-clamp: unset;
}

.showMore {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .cardWrapper {
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 100%;
  }

  .cardWrapper.noBox {
    padding: 0;
    border: none;
    border-radius: 0;
  }
}
