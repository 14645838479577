@import "helpers/_variables";

.checkdate-field-container {
  padding: 12px;
  // border-radius: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 135px;

  .text-input-container {
    padding-left: 16px;
    padding-top: 2px;
  }

  .cross-icon {
    visibility: hidden;
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 0px 4px;
    border-radius: 50%;
  }

  .checkdate-heading {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #bf0101;
  }

  .checkdate-subhead {
    border: none;
    font-size: 14px;
    // font-weight: bold;
    outline: none;
    background-color: transparent;
    margin-left: -1px;
    color: grey;
  }

  .seprator {
    align-self: center;
    border-right: 1px solid #dddddd;
    height: 40px;
  }
}

.checkdate-field-container:hover {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.checkdate-field-container.active {
  // transform: scale(1.01, 1.02);
  // background-color: white;
  // -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.checkdate-field-container.reduce-date-width {
  width: 161px !important;
}

.check-dates-container {
  display: flex;
  // gap: 10px; /* Adjust the gap between CheckIn and CheckOut fields */
}
