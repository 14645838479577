@import "helpers/_variables";

.guests-field-container {
  padding: 12px;
  display: flex;
  cursor: pointer;
  min-width: 223px;
  align-items: center;
  // border-radius: 32px;
  justify-content: space-between;

  .text-input-container {
    padding-top: 2px;
    padding-left: 16px;
  }

  .cross-icon {
    padding: 0px 4px;
    margin-left: 20px;
    visibility: hidden;
    border-radius: 50%;
    margin-right: 20px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }

  .reduce-width {
    margin-left: 15px;
    margin-right: 15px;
  }

  .guests-heading {
    color: #bf0101;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  .guests-subhead {
    border: none;
    outline: none;
    color: grey;
    font-size: 14px;
    margin-left: -1px;
    // font-weight: bold;
    // color: black;
    color: gray;
    background-color: transparent;
  }

  .search-btn {
    margin: -5px;
    display: flex;
    padding: 12px 0px;
    border-radius: 50px;
    background-color: $primary-color;
  }

  .search-btn:hover {
    margin: -5px;
    display: flex;
    padding: 12px 0px;
    border-radius: 50px;
    background-color: rgb(245, 76, 47);
  }

  .search-text {
    color: white;
    font-weight: bold;
    margin-left: -6px;
    margin-right: 18px;
  }

  .search-icon {
    margin: 0px 12px;
  }
}

.guests-field-container:hover {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.guests-field-container.active {
  // transform: scale(1.01, 1.02);
  // background-color: white;
  // -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);
  background-color: white;
}

// Guests form
.guests-type-container {
  background-color: white;
  position: absolute;
  margin-top: 350px;
  width: 100%;
  margin-left: -125px;
  // border-radius: 40px;
  max-width: 400px;
  min-height: 80px;
  overflow: auto;
  padding: 24px 40px;
  -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);

  .guests-type-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    border-bottom: 1px solid #dddddd;
  }

  .guests-type-row:last-child {
    border-bottom: none;
  }

  .heading {
    font-size: 16px;
    font-weight: bold;
    word-wrap: break-word;
  }

  .description {
    font-size: 14px;
    color: gray;
  }

  .guests-type-btn-box {
    display: flex;
    align-items: center;
  }

  .minus {
    cursor: pointer;
  }

  .count {
    padding: 0px 16px;
    font-size: 16px;
    width: 50px;
    text-align: center;
    box-sizing: border-box;
  }

  .plus {
    cursor: pointer;
  }
  /* Mobile-specific styles */
  @media (max-width: 600px) {
    margin-top: 24px;
    margin-left: 0;
    padding: 16px;
    max-width: 100%;
    width: 90%;
    box-sizing: border-box;

    .guests-type-row {
      padding: 8px 0;
    }

    .heading {
      font-size: 14px;
    }

    .description {
      font-size: 12px;
    }

    .count {
      padding: 0 12px;
      font-size: 14px;
      width: 40px;
    }

    .minus,
    .plus {
      padding: 6px;
    }
  }
}

.guests-type-container.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
