.whatsapp-floating-button {
  background-color: #25d366;
  margin-top: 10px;
  width: 170px;
  height: 40px;
  border-radius: 10px;
  border: none;
}

.whatsapp-icon {
  margin-left: 5px;
}
