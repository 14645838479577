// @import "helpers/_variables";

// .search-container {
//   margin: auto;
//   margin-top: 32px;
//   margin-bottom: 8px;
//   color: black;
//   display: flex;
//   justify-content: center;
//   text-align: justify;

//   .search-style {
//     background-color: white;
//     border-radius: 8px 0 0 8px;
//     border: 1px solid #dddddd;
//     display: flex;
//     height: 86px;
//     width: max-content;
//   }

//   .disabled {
//     background-color: #f7f7f7;
//   }

//   .seprator {
//     align-self: center;
//     border-right: 1px solid #dddddd;
//     height: 40px;
//   }

//   .search-custom {
//     background-color: #590000;
//     padding: 20.5px;
//     cursor: pointer;
//     border-radius: 0 8px 8px 0;
//     align-content: center;
//   }

//   .check-dates-container {
//     display: flex;
//     gap: 10px;
//   }
//   .check-available {
//     color: #ffffff;
//     font-family: "Poppins", sans-serif;
//     font-weight: 400;
//     line-height: 27px;
//     white-space: nowrap;
//     margin-left: 5px;
//   }
//   .search-icons {
//     color: #dddddd !important;
//   }
// }

.search-container {
  margin: auto;
  margin-top: 32px;
  margin-bottom: 8px;
  color: black;
  display: flex;
  justify-content: center;
  text-align: justify;
  padding: 1rem;
  width: 100%;

  .search-style {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 0;
    }
  }

  .search-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .check-dates-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

    @media (min-width: 768px) {
      gap: 10px;
    }
  }

  .search-custom {
    background-color: #590000;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      padding: 20.5px;
      width: auto;
    }
  }

  .check-available {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 27px;
    white-space: nowrap;
    margin-left: 5px;
    font-size: 0.875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  .search-icons {
    color: #dddddd !important;
    font-size: 20px;

    @media (min-width: 768px) {
      font-size: 25px;
    }
  }
}

@media (min-width: 1024px) {
  .search-container {
    margin: auto;
    max-width: 1280px;
    padding: 0;
  }

  .search-style {
    height: 86px;
    max-width: 80%;
  }

  .search-custom {
    padding: 20.5px;
  }
}

@media (max-width: 768px) {
  .search-container {
    padding: 0px;

    .search-row {
      border: 1px solid rgb(196, 191, 191);
      border-radius: 2px;
    }

    .search-style {
      flex-direction: column;
      height: auto;
      border-radius: 1rem;
      width: 100%;
      gap: 16px;
    }
  }
}

@media (max-width: 480px) {
  .search-container {
    padding: 0px;

    .search-row {
      border: 1px solid rgb(196, 191, 191);
      border-radius: 2px;
    }

    .search-style {
      flex-direction: column;
      height: auto;
      border-radius: 1rem;
      width: 100%;
      gap: 16px; /* Maintain gap for mobile view */
    }
  }

  .check-dates-container {
    flex-direction: row; /* Ensure CheckIn and CheckOut stay in the same row */
    gap: 10px; /* Adjust gap between the two date pickers */
  }

  .search-custom {
    width: 100%;
    padding: 14px;
  }
}

.slider-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  padding: 20px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  transform: translateX(0); // Open state

  &.closed {
    transform: translateX(100%); // Closed state
  }

  .close-slider {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
  }
}
