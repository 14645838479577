@import "helpers/_variables";

.hotel-details-header {
  margin-top: 16px;

  h2 {
    font-weight: bold;
  }

  .text {
    text-decoration: underline;
    cursor: pointer;
  }

  .hotel-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    margin-bottom: 2rem;
  }

  .hotel-info .star {
    font-weight: normal;
    color: $primary-color;
    font-size: 1.25rem;
    margin-right: 4px;
  }

  .hotel-info .share {
    font-weight: bold;
    cursor: pointer;
  }

  .hotel-info .reviews {
    color: $text-color;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
  }
}