@import 'helpers/_variables';

.ratingsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 16px;
  margin-bottom: 24px;
}

.ratingLabel {
  font-size: 16px;
}

.ratingWrapper {
  width: calc(50% - 24px);
}

.star {
  line-height: 1;
}

.textarea {
  margin-bottom: 24px;
}

@media screen and (max-width: $desktop) {
  .ratingWrapper {
    width: 100%;
  }
}