@import "helpers/_variables";

.hotel-details-container {
  padding-top: 90px;
  padding-bottom: 30px;
  min-height: 100vh;

  .hotel-details-style {
    width: 82%;
    margin: 0 auto;
  }

  .displayFlex {
    display: flex;
    flex-wrap: wrap;
  }

  .displayItem {
    width: 60%;
  }

  .displayItem2 {
    width: 40%;
  }

  .hotel-detail-enquiry {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    margin-left: 50px;
  }
}

@media (max-width: $mobile) {
  .hotel-details-container {
    .displayItem {
      width: 100%;
    }

    .hotel-details-style {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      margin: 0 auto;
    }

    .displayItem2 {
      width: 100%;
    }

    .hotel-detail-enquiry {
      margin-left: 0px;
    }
  }
}