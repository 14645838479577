@import 'helpers/_variables';

.reviewModal {
  width: 50%;
}

.reviewBtn {
  width: fit-content;
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (max-width: $large-desktop) {
  .reviewModal {
    width: auto;
  }

  .reviewBtn {
    width: 100%;
  }
}