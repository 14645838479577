.room-specification {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 110px 0px;
  padding: 0px 36px;

  @media (max-width: 768px) {
    margin: 50px 0px;
    padding: 0px 20px;
  }

  &__text {
    text-align: center;
    color: #4a5568;
    font-size: clamp(0.875rem, 1.5vw + 0.5rem, 1.125rem);

    @media (min-width: 768px) {
      font-size: clamp(1rem, 1.5vw + 0.5rem, 1.125rem);
    }
  }

  &__loading,
  &__error,
  &__no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: clamp(1rem, 2vw, 2rem);

    .room-specification__text {
      color: #363636;
    }
  }

  /* Large Screen Layout - Hidden on Mobile */
  &__large-screen {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;

      .room-specification__icons-left,
      .room-specification__icons-right {
        display: flex;
        flex-direction: column;
        gap: clamp(1rem, 6vw, 6rem);

        .room-specification__icon-item_left:nth-child(1),
        .room-specification__icon-item_left:nth-child(3) {
          padding-right: 0;
        }

        .room-specification__icon-item_left:nth-child(2) {
          padding-right: clamp(1rem, 2vw, 2rem);
        }
      }

      .room-specification__icons-left,
      .room-specification__icons-right {
        flex-basis: 45%; // Ensure both sides take up balanced space
      }

      .room-specification__icons-right {
        display: flex;
        flex-direction: column;
        gap: clamp(1rem, 6vw, 3rem);

        .room-specification__icon-item:nth-child(1),
        .room-specification__icon-item:nth-child(3) {
          padding-left: 0;
        }

        .room-specification__icon-item:nth-child(2) {
          padding-left: clamp(1rem, 2vw, 2rem);
        }
      }

      .room-specification__central-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: clamp(200px, 50vw, 730px);
        aspect-ratio: 1;

        img {
          border-radius: 50%;
          border: 2px dashed #bbebeb;
          width: 100%;
          height: 100%;
          object-fit: cover;
          padding: clamp(2px, 1vw, 5px);
        }
      }

      /* Left-side icon styles */
      .room-specification__icon-item_left {
        display: flex;
        gap: clamp(0.5rem, 1vw, 0.5rem);

        .room-specification__icon-image {
          width: clamp(60px, 8vw, 70px);
          height: clamp(60px, 8vw, 70px);
          object-fit: contain;
        }

        .room-specification__icon-details {
          text-align: right;

          .room-specification__icon-title {
            color: #363636;
            font-family: "Josefin Sans", sans-serif;
            font-weight: 400;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            line-height: 1.2;
          }

          .room-specification__icon-description {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: clamp(0.75rem, 1vw, 0.875rem);
          }
        }
      }

      /* Right-side icon styles */
      .room-specification__icon-item {
        display: flex;
        gap: clamp(0.5rem, 1vw, 0.5rem);

        .room-specification__icon-image {
          width: clamp(50px, 6vw, 60px);
          height: clamp(50px, 6vw, 60px);
          object-fit: contain;
        }

        .room-specification__icon-details {
          text-align: left;

          .room-specification__icon-title {
            color: #363636;
            font-family: "Josefin Sans", sans-serif;
            font-weight: 400;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            line-height: 1.2;
          }

          .room-specification__icon-description {
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: clamp(0.75rem, 1vw, 0.875rem);
          }
        }
      }
    }
  }

  /* Small Screen Layout - Hidden on Large Screens */
  &__small-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(1rem, 2vw, 2rem);

    @media (min-width: 768px) {
      display: none;
    }

    .room-specification__central-image {
      width: clamp(250px, 30vw, 300px);
      height: clamp(250px, 30vw, 300px);
      margin-bottom: clamp(1rem, 2vw, 2rem);

      img {
        border-radius: 50%;
        border: 4px dashed #e2e8f0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .room-specification__icon-list {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(150px, 1fr)
      ); // Responsive grid
      gap: clamp(1rem, 2vw, 1.5rem);
      justify-content: center;

      .room-specification__icon-item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .room-specification__icon-image {
          width: clamp(40px, 5vw, 50px);
          height: clamp(40px, 5vw, 50px);
          object-fit: contain;
        }

        .room-specification__icon-details {
          margin-top: clamp(0.5rem, 1vw, 0.5rem);

          .room-specification__icon-title {
            font-size: clamp(0.875rem, 1vw, 0.875rem);
            color: #e53e3e;
          }

          .room-specification__icon-description {
            font-size: clamp(0.75rem, 0.8vw, 0.75rem);
          }
        }
      }
    }
  }

  &__footer-text {
    margin-top: clamp(1rem, 2vw, 2rem);
    text-align: center;
    color: #4a5568;
    font-size: clamp(20px, 2vw + 2px, 32px);
    font-family: "Josefin Sans", sans-serif;
    font-weight: 400;
    line-height: clamp(25px, 2vw + 2px, 55px);
    padding: 0 clamp(20px, 5vw, 110px);

    @media (max-width: 1024px) {
      font-size: clamp(20px, 2vw, 24px);
      line-height: clamp(25px, 2vw, 35px);
      padding: 0 clamp(20px, 5vw, 80px);
    }

    @media (max-width: 768px) {
      font-size: clamp(16px, 2vw, 20px);
      line-height: clamp(20px, 2vw, 30px);
    }
  }
}
