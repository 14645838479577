.form-select {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px;
  height: 60px;
  margin: 8px 0px 2px 0px;
  
  .form-select-label {
    font-size: 12px;
    color: gray;
    padding: 4px 8px;
  }

  .form-select-dropdown {
    border: none;
    background-color: white;
    width: 100%;
    font-size: 16px;
    padding: 0px 8px;
    outline: none;

    option {
      font-size: 13px;
    }

    &:focus {
      outline: none
    }
  }
}

.form-select:focus {
  border: 1px solid black;
}
