.call-support {
  display: flex;
  align-items: center;

  .call-button {
    display: flex;
    background-color: transparent;
    color: rgb(47, 46, 46);
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s;
    gap: 12px;

    .call-icon {
      font-size: 18px;
    }

    .call-text {
      display: flex;
      flex-direction: column;
      line-height: 1.2;
    }

    .call-number {
      padding-top: 5px;
      align-self: flex-end;
      font-size: 16px;
      font-weight: normal;
      color: #111111;
    }
  }
}
