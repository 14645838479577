$desktop: 768px; // xs
$mobile: 540px; // sm - 576
$small-mobile: 480px; // md
$large-desktop: 992px; // lg
$larger-desktop: 1200px; //xl
$extra-large-desktop: 1600px; //xxl

$hover-color: rgb(246, 246, 246);
$divider-color: 1px solid rgba(0, 0, 0, .1);

$primary-color: #FE0101;
$heading-color: #000;
$text-color: #3C3C3C;