.reviewModal {
  margin: 0 0px !important;
}
.confirmBtn {
  background-color: #fe0101;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 12px;
  margin-left: 10px;
  width: 40%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #de0303;
  }
}

.cancellationList {
  list-style: none;
  padding: 0;
  margin: 0;

  .policyTitle {
    font-weight: 600;
    font-size: 18px;
    color: #333;
  }

  .cancellationRow {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    font-size: 16px;
    width: 100%;
  }

  .daysDescription {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    color: #333;
  }

  .refundDescription {
    flex: 2;
    font-size: 14px;
    color: #333;
  }

  .divider {
    border: none;
    border-top: 1px solid #dedede;
    margin: 10px 0;
    width: 100%;
  }
}

// .cancellationRow {
//   display: flex;
//   justify-content: space-between;
//   align-items: baseline;
//   padding: 10px 0;

//   .daysDescription {
//     font-weight: 500;
//     font-size: 16px;
//     margin-bottom: 4px;
//     margin-right: 6px;
//     color: #555;
//   }

//   .refundDescription {
//     font-weight: 400;
//     font-size: 14px;
//     color: #777;
//     margin-bottom: 8px;
//   }

//   .divider {
//     border: none;
//     border-top: 1px solid #ddd;
//     margin: 8px 0;
//   }
// }
