@import "../../../../helpers/variables";

.horizontal-scroll-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.horizontal-scroll-wrapper::-webkit-scrollbar {
  display: none !important;
}

.hotel-item-container2 {
  padding: 8px;
  height: 300px;
  margin: 24px 0px;
  background-color: white;
  width: 260px;
  border: 1px solid lightgray;
  border-radius: 8px !important;

  .hotel-item-description {
    width: 100%;
    padding: 0px 6px;
  }

  .hotel-item-rating {
    height: 34px;
    font-size: 16px;
    font-weight: bold;
  }

  .hotel-item-rating .users {
    font-weight: normal;
    margin-left: 4px;
  }

  .hotel-item-rating .star {
    font-weight: normal;
    color: $primary-color;
    font-size: 20px;
    margin-right: 4px;
  }

  .hotel-item-price {
    font-weight: bold;
    font-size: 20px;
    color: $heading-color;
  }

  .hotel-item-type {
    font-size: 14px;
    color: $heading-color;
    margin: 6px 0px;
  }

  .hotel-item-name {
    font-size: 18px;
    color: #222222;
    margin-bottom: 4px;
  }

  .hotel-item-rooms-type {
    font-size: 14px;
    color: $text-color;
    height: 24px;
  }

  .hotel-item-amenities {
    font-size: 14px;
    color: $text-color;
    height: 20px;
  }

  .hotel-item-image {
    border: 1px solid gray;
    height: 100px;
    width: 300px;
    width: 100%;
    border-radius: 8px;
  }

  .hotel-item-rating-price {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .hotel-item-price .per-night {
    font-weight: normal;
    font-size: 18px;
  }
}

.hotel-item-container2:hover {
  border: 1px solid lightgray;
  margin: 24px 0px;
  cursor: pointer;
  padding: 8px;
  background-color: rgb(246, 246, 246);
}