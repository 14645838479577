.form-buttons {
  width: 100%;
  padding: 10px;
  background-color: #ff0000;
  border: none;
  font-weight: bold;
  font-size: 16px;
  border-radius: 8px;
  color: white;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cc0000;
  }

  &:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }
}
