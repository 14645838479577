.modal-container {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 400;
  background-color: rgba($color: #000000, $alpha: 0.6);

  .modal-box {
    border-radius: 8px;
    min-width: 300px;
    color: black;
    background-color: #ffffff;
    margin: 0 1rem;
    position: relative;
    animation-name: drop;
    animation-duration: 0.5s;
    margin-top: 0px;
  }

  .modal-heading {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .modal-heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid lightgray;
  }

  .modal-close-icon {
    font-size: 16px;
  }

  .modal-close-icon:hover {
    font-size: 16px;
    background-color: lightgray;
    border-radius: 50%;
  }

  .modal-content-container {
    padding: 24px;
    overflow: auto;
    max-height: 80vh;
  }
}

@keyframes drop {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0px);
  }
}
