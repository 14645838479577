@import "helpers/_variables";

$font-size-base: .8rem !default;

.loading {
  color: $primary-color;
  font-size: $font-size-base*5;
  text-align: center;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: $primary-color;
    text-shadow: $font-size-base*1.25 0 0 $primary-color,
    $font-size-base*2.5 0 0 $primary-color;
  }
  40% {
    color: white;
    text-shadow: $font-size-base*1.25 0 0 $primary-color,
    $font-size-base*2.5 0 0 $primary-color;
  }
  60% {
    text-shadow: $font-size-base*1.25 0 0 white,
    $font-size-base*2.5 0 0 $primary-color;
  }
  80%, 100% {
    text-shadow: $font-size-base*1.25 0 0 white,
    $font-size-base*2.5 0 0 white;
  }
}
