html {
  font-size: 16px;
  scroll-behavior: smooth;
  color: #3c3c3c;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif !important;
  /* font-family: 'Nunito Suno','Roboto', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3c3c3c !important;
  font-weight: 300 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spinner {
  animation: spin infinite 2s linear;
}

.color-primary {
  color: #fe0101 !important;
}

.cursor-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

* {
  box-sizing: border-box;
  /* color: #3c3c3c; */
}
