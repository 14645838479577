.btn-container {
  .btn-style {
    padding: 7px 15px;
    background-color: white;
    border-radius: 8px;
    width: fit-content;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
}