@import "helpers/_variables";

.amenities-container {

  .amenity-list-style {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    padding: 16px 0 0;
  }

  .view-more {
    color: $primary-color;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 4px 16px;
  }

  .amenity-item {
    padding: 0;
    width: 70px;
  }

  .icon-container {
    text-align: center;
  }

  .icon {
    width: 26px;
    height: 26px;
  }

  .name {
    font-size: 12px;
    text-align: center;
    margin: 12px 0px;
  }
}