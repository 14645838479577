.modalReviewCardWrapper {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 16%;
}

.modalReviewCardWrapper .modalReviewCard {
  width: 100%;
}

.modal {
  max-width: 1032px;
}

.modalHeader {
  border-bottom: 0 !important;
}

.modalBody {
  padding-top: 0 !important;
  scroll-behavior: smooth;
}

.modalContent {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}

.modalReviewCardWrapper {
  min-width: 55%;
  margin-top: 0;
}

.searchWrapper {
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  top: 16px;
  left: 12px;
}

.searchInput {
  display: block;
  width: 100%;
  padding: 12px 36px;
  border-radius: 40px;
  background-color: #f7f7f7;
  border: 1px solid #222;
}

.clearIcon {
  position: absolute;
  top: 16px;
  right: 12px;
}

@media screen and (max-width: 767px) {
  .modalContainer {
    align-items: unset;
  }

  .modal {
    max-width: 100%;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .modalReviewCardWrapper {
    width: 100%;
  }

  .modalContent {
    flex-wrap: wrap;
    gap: 48px;
  }

  .modalBody {
    max-height: calc(100vh - 66px);
  }
}