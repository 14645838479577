.custom-dot {
  bottom: -1rem;
  width: 10px;
  height: 10px;
  background-color: #e2e8f0;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;

  &.selected {
    background-color: #bf0101;
  }
}
.testimonial-container {
  position: relative;
  padding: 2rem 1rem;
  background-color: #fff;

  @media (max-width: 767px) {
    padding: 2.5rem 1.5rem;
  }

  @media (min-width: 768px) {
    padding: 3rem 1.5rem;
  }

  @media (min-width: 1024px) {
    padding: 3rem 5rem;
  }
}

.testimonial-content {
  position: relative;
  max-width: 66rem;
  margin: 0 auto;
  text-align: center;
}

.quote-left,
.quote-right {
  color: #e5e5e5 !important;
  font-size: 2rem;

  @media (min-width: 480px) {
    font-size: 1.5rem;
  }

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;
  }
}

.quote-left {
  position: absolute;
  left: 1rem;
  top: 1.5rem;
  transform: translateY(-50%);

  @media (min-width: 768px) {
    left: 1rem;
    top: 2.5rem;
  }

  @media (min-width: 1024px) {
    left: 2rem;
  }
}

.quote-right {
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
  transform: translateY(50%);

  @media (min-width: 768px) {
    right: 1rem;
    bottom: 2.5rem;
  }

  @media (min-width: 1024px) {
    right: 2rem;
  }
}

.testimonial-header {
  color: #bf0101;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-bottom: 0.5rem;

  @media (min-width: 480px) {
    font-size: 22px;
  }

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
}

.testimonial-title {
  font-family: "Josefin Sans", sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 480px) {
    font-size: 36px;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.testimonial-description {
  color: #4a5568;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;

  &.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit to 4 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.full {
    display: block;
  }

  @media (max-width: 767px) {
    padding: 0 1.5rem;
  }

  @media (min-width: 480px) {
    font-size: 16px;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
    padding: 0;
  }
}

.show-more-button {
  background-color: transparent;
  color: #bf0101;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

.testimonial-person {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #2d3748;

  @media (min-width: 480px) {
    font-size: 24px;
  }

  @media (min-width: 768px) {
    font-size: 28px;
  }
}

.testimonial-rating {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.star-filled {
  color: #f6ad55;
}

.star-empty {
  color: #e2e8f0;
}

.loading-text {
  color: #4a5568;
}
