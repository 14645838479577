.headingWrapper {
  display: flex;
  gap: 6px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;

  * {
    color: black;
  }
}

.reviewCardWrapper {
  // margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 16%;
}

.reviewCard {
  width: 100%;
}

.showMoreBtn {
  margin-top: 32px;
  outline: 0;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
}

.scrollContainer {
  flex: 3;
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .scrollContainer {
    margin: 0 -24px;
    flex: 1;
  }

  .reviewCardWrapper {
    max-width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    column-gap: 16px;
    margin-top: 24px;
  }

  .reviewCardWrapper::-webkit-scrollbar {
    display: block;
    height: 0;
  }

  .reviewCard {
    flex: 0 0 80%;
  }

  .reviewCard:first-of-type {
    margin-left: 24px;
  }

  .reviewCard:last-of-type {
    margin-right: 24px;
  }

  .showMoreBtn {
    width: 100%;
  }
}

.reviewSetion {
  display: flex;
  justify-content: space-between;
}
