@import 'helpers/_variables';

.bookingReceiptContainer {
  padding: 64px 48px;
}

.idWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.id {
  font-size: 14px;
  margin-top: 16px;
}

.cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto;
  gap: 24px;
  margin: 12px 0 16px;
}

.receiptWrapper {
  grid-column: 1;
  grid-row: span 2;
}

.bookingDetailsCard {
  border: 1px solid gray;
  padding: 16px;
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bookingLocation {
  font-size: 16px;
  color: $text-color;
}

.bookingNights {
  font-size: 15px;
  margin-top: 12px;
  font-weight: bold;
}

.bookingDates {
  font-size: 16px;
  margin: 6px 0px;
}

.bookingHotelType {
  font-size: 16px;
  margin: 6px 0px;
}

.description {
  color: $text-color;
  font-size: 12px;
  margin: 8px 0px;
}

.subHeading {
  font-size: 14px;
  font-weight: 600;
  margin: 12px 0;
}

@media screen and (max-width: 1023px) {
  .cardWrapper {
    grid-template-columns: 1fr;
  }
}