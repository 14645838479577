.formField {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px;
  height: auto;
  margin: 8px 0px 2px 0px;

  .formFieldLabel {
    font-size: 12px;
    color: gray;
    padding: 4px 8px;
  }

  .formFieldInput {
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 0px 8px;
    outline: none
  }
}

.formField:focus {
  border: 1px solid black;
}