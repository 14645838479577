@import "../../helpers/variables";

.host-details-page-container {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;

  .host-details-page {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      width: 90%;
      flex-direction: column;
      flex-flow: column-reverse;
    }

    .left-container {
      margin-top: 40px;
      padding: 24px;
      width: 308px;
      border-radius: 12px;
      border: 1px solid lightgray;

      @media (max-width: 600px) {
        width: 90%;
        margin: 0 auto;
        flex-direction: column;
        flex-flow: column-reverse;
      }
    }

    .image-style {
      height: 128px;
      width: 128px;
      border-radius: 100%;
      border: 1px solid lightgray;
      margin: 4px auto;
      display: block;
      margin-bottom: 24px;
    }

    .review-count {
      color: black;
      font-size: 16px;
      margin: 16px 0px;
    }

    .host-desc {
      font-size: 22px;
      font-weight: bold;
      margin: 20px 0px;
    }

    .host-desc2 {
      font-size: 16px;
      margin: 16px 0px;
    }

    .host-desc3 {
      font-size: 14px;
      margin-top: 32px;
    }

    //=================================================================

    .right-container {
      width: 65.5%;
      max-width: 700px;
      padding-left: 30px;
      padding-top: 36px;

      @media (max-width: 600px) {
        padding-left: 0px;
        margin: 0 auto;
        margin-bottom: 60px;
        width: 90% !important;
      }

      .title {
        font-size: 32px;
        color: black;
        font-weight: bold;
      }

      .subtitle {
        font-size: 14px;
        color: $text-color;
      }

      .heading {
        font-size: 22px;
        font-weight: bold;
        margin: 26px 0px;
      }

      .description {
        margin-bottom: 40px;
      }
    }
  }
}

.host-details-listing {
  margin-right: 16px;
  margin-bottom: 20px;

  .image {
    width: 306px;
    height: 200px;
    border-radius: 12px;
  }

  .rating {
    margin-top: 8px;
    font-size: 14px;
  }
}

.host-listing-list {
  overflow: auto;
}

.host-listing-list::-webkit-scrollbar {
  height: 4px;
}

.host-listing-list::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}


.host-review {
  margin: 16px 0px;

  .image {
    height: 55px;
    width: 55px;
    border-radius: 100%;
  }

  .rtitle {
    font-size: 18px;
    font-weight: bold;
  }
}

.show-more-btn {
  margin-top: 32px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid black;
  border-radius: 12px;
  padding: 12px;
}