.formDatePicker {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px;
  height: 60px;
  margin: 8px 0px;

  .formDatePickerLabel {
    font-size: 12px;
    color: gray;
    padding: 4px 8px;
  }

  .formDatePickerInput {
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 0px 8px;
    outline: none
  }
}

.formDatePicker:focus {
  border: 1px solid black;
}