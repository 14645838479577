@import 'helpers/_variables';

.profileContainer {
  padding: 100px 48px 48px;
}

.profileHeading {
  font-size: 24px;
  font-weight: bold;
  color: rgb(43, 42, 42);
}

.formWrapper {
  max-width: 50%;
  margin: auto;
}

.btnWrapper {
  margin-top: 24px;
}

.inputWrapper {
  position: relative;
}

.verify {
  position: absolute;
  bottom: 7px;
  right: 16px;
  color: green;
}

.verify.link {
  cursor: pointer;
  color: #ff7400;

  &:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: $desktop) {
  .profileContainer {
    padding: 100px 24px 24px;
  }

  .formWrapper {
    max-width: 100%;
  }
}