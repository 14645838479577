@import 'helpers/_variables';

.about-container {
  .read-more {
    color: $primary-color;
    cursor: pointer;
    font-weight: bold;
  }

  .description {
    padding: 0;
    text-align: justify;
    margin-bottom: 0;
  }

  .descriptions-content {
    padding: 0;
    text-align: justify;
  }
}