@import "helpers/_variables";

.search-field-container {
  width: 300px;
  border: 1px solid #dddddd;
  border-radius: 32px;
  padding: 11px 16px 11px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: rgba($color: #000000, $alpha: 0.8);
  cursor: pointer;

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;
  }

  .search-btn {
    background-color: $primary-color;
    padding: 6px 0px;
    display: flex;
    border-radius: 50px;
    margin: -8px;
  }

  .search-icon {
    margin: 0px 6px;
  }
}

.search-field-container:hover {
  box-shadow: 0px 2px 4px 1px rgb(233, 226, 226);
}