.mobile-calendar-containers {
  background-color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 16px 30px;
  display: flex;
  flex-direction: column;

  .back-icon {
    margin-left: -8px;
  }

  .mobile-calendar-clear {
    text-decoration: underline;
    text-align: end;
  }

  .bottom-controller {
    height: 80px;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0px -30px;
    display: flex;
    align-items: center;
    z-index: 10;
    background-color: white;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid rgb(184, 181, 181);
  }

  .bottom-skip {
    color: black;
    text-decoration: underline;
  }

  .bottom-next {
    background-color: rgb(253, 15, 15);
    color: white;
    border-radius: 8px;
    padding: 12px 24%;
  }

  .bottom-next.active {
    background-color: gray;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_start:hover,
  .CalendarDay__selected_start:active {
    background-color: #423b7d;
  }

  .CalendarDay__selected_end,
  .CalendarDay__selected_end:hover,
  .CalendarDay__selected_end:active {
    background-color: #ff7400;
  }
}
