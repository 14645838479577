@import 'helpers/_variables';

.form-button {
    width: 100%;
    background-color: $primary-color;
    border: none;
    height: 48px;
    font-weight: bold;
    font-size: 16px;
    border-radius: 8px;
    color: white;
    margin: 4px 0px;
    outline: none;
}

.form-button:disabled {
    background-color: lightgray;
}