.alignCenter {
  display: flex;
  align-items: center;
}

.ratingWrapper {
  @extend .alignCenter;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  border-right: 1px solid #dedede;

  // flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  // padding-right: 40px;
}

.rating {
  width: 42%;
  @extend .alignCenter;
  // justify-content: space-between;
  gap: 4px;
}

.type {
  font-size: 16px;
  white-space: nowrap;
}

.barWrapper {
  @extend .alignCenter;
  gap: 8px;
}

.bar {
  min-width: 20px;
  max-width: 150px;
}

.rating.inOneRow {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .rating {
    width: 100%;
  }
}
