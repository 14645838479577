@import "helpers/_variables";

// Footer Section
.footer-section-container {
  background-color: #f7f7f7 !important;
  border-top: 1px solid #dddddd !important;

  .footer-section-style {
    padding: 0px 24px;
    justify-content: center;
  }

  .footer-section-links-container {
    margin: 12px 0px 48px;
  }

  .footer-section-links-heading {
    font-size: 12px;
    font-weight: bold;
    margin-top: 36px;
  }

  .footer-section-links-heading + ul {
    list-style-type: none;
  }

  .footer-section-links > div {
    font-size: 14px;
    margin-top: 16px;
  }

  .footer-section-links > div:hover {
    color: grey;
    text-decoration: underline;
    cursor: pointer;
  }
}

// Footer Component
.footer {
  border-top: $divider-color;
  padding: 22px 0px;
  justify-content: space-between;

  .footer-left {
    font-size: 14px;
    padding: 0;
  }

  .footer-left > span {
    padding: 0px 8px;
    cursor: pointer;
  }

  .footer-left > span:hover {
    color: grey;
    text-decoration: underline;
  }

  .footer-right {
    font-size: 15px;
    color: black;
    margin-right: 12px;
    letter-spacing: -1px;
  }

  .footer-right > span {
    text-decoration: underline;
    cursor: pointer;
  }

  .footer-right > span:hover {
    color: grey;
  }
}

@media (max-width: $mobile) {
  .footer {
    .footer-right {
      margin-top: 20px;
    }
  }
}
