.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #000;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.loading-text {
  color: #fff;
}
