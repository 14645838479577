.reviewPage {
  padding: 24px;
  max-width: 600px;
  margin: 72px auto 24px;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.submitted {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}