@import "helpers/_variables";

.m-add-guest-container {
  background-color: white;
  position: fixed;
  z-index: 400;
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding: 16px 30px;

  .back-icon {
    margin-left: -8px;
  }

  .guests-clear {
    font-size: 16px;
    text-decoration: underline;
    text-align: end;
  }

  .guests-heading {
    margin-top: 34px;
    font-weight: bold;
    font-size: 22px;
  }

  .m-guests-type-container {
    background-color: white;
    width: 100%;
    max-width: 400px;
    min-height: 80px;
    overflow: auto;
    padding: 24px 0px;

    .guests-type-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0px;
      border-bottom: 1px solid #dddddd;
    }

    .guests-type-row:last-child {
      border-bottom: none;
    }

    .heading {
      font-size: 16px;
      font-weight: bold;
      word-wrap: break-word;
    }

    .description {
      font-size: 14px;
      color: $text-color;
    }

    .guests-type-btn-box {
      display: flex;
      align-items: center;
    }

    .minus {
      cursor: pointer;
    }

    .count {
      padding: 0px 16px;
      font-size: 16px;
      width: 50px;
      text-align: center;
      box-sizing: border-box;
    }

    .plus {
      cursor: pointer;
    }
  }

  .guests-type-container.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  .bottom-controller {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0px -30px;
    display: flex;
    align-items: center;
    z-index: 10;
    background-color: white;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid rgb(184, 181, 181);
  }

  .bottom-skip {
    color: $heading-color;
    text-decoration: underline;
  }

  .bottom-next {
    background-color: $primary-color;
    color: white;
    border-radius: 8px;
    padding: 16px 24%;
  }

  .bottom-next.active {
    background-color: lightgray;
  }
}