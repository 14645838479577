@import "helpers/_variables";

.userdropdown-style {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dddddd;
  border-radius: 30px;
  transition: background-color 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .user-transparent-container {
    padding: 20px;
    min-width: 270px;
    display: none;
    margin-top: 60px;
    padding-top: 12px;
    border-radius: 8px;
    padding-top: 12px;
    position: fixed;
    right: 0;
    z-index: 50;

    .user-dropdown-container {
      background-color: white;
      border-radius: 8px;
      min-width: 230px;
      position: fixed;
      border: 1px solid lightgray;
      right: 0;
      margin-right: 40px;
      z-index: 50;

      .user-dropdown-list {
        padding: 8px 0px;
        list-style: none;
      }

      .user-dropdown-list > li {
        list-style: none;
        margin: 0px 70px;
        padding: 8px 16px;
        width: 100%;
        margin-left: 0px;
        color: $text-color;
        font-size: 14px;
        cursor: pointer;
      }

      .user-dropdown-list > li:hover {
        background-color: var(--light);
      }
    }
  }
}

.userdropdown-style:hover > .user-transparent-container {
  display: block;
}

.auth-list-container {
  right: 5rem;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  z-index: 1000;
  width: 13rem;

  .auth-list > li {
    list-style: none;
    padding: 0.5rem 0;
    font-weight: 400;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: var(--light);
    }
  }

  &:hover {
    display: block !important;
  }
}

.userImg {
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
  border-radius: 50%;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgb(52, 153, 247);
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  border-radius: 50%;
}
