// @import "helpers/_variables";

// // Footer Section
// .footer-section-container {
//   background-color: #2a2a2a !important;
//   border-top: 1px solid #dddddd !important;

//   .footer-section-style {
//     padding: 0px 24px;
//     justify-content: center;
//   }

//   .footer-section-links-container {
//     margin: 12px 0px 48px;
//   }

//   .footer-section-links-heading {
//     color: #bbebeb;
//     font-size: 22px;
//     font-weight: 600;
//     margin-top: 36px;
//     line-height: 52px;
//     font-family: Poppins;
//   }

//   .footer-section-links-heading + ul {
//     color: #bbebeb;
//     list-style-type: none;
//   }

//   .footer-section-links > div {
//     color: #ffffff;
//     font-size: 14px;
//     margin-top: 16px;
//   }

//   .footer-section-links > div:hover {
//     color: #ffffff;
//     text-decoration: underline;
//     cursor: pointer;
//   }
// }

// // Footer Component
// .footer {
//   border-top: $divider-color;
//   padding: 22px 0px;
//   width: 100%;
//   background-color: #dddddd;
//   justify-content: space-between;

//   .footer-left {
//     color: #484848;
//     font-size: 14px;
//     padding: 0;
//   }

//   .footer-left > span {
//     padding: 0px 8px;
//     color: #484848;
//     cursor: pointer;
//   }

//   .footer-left > span:hover {
//     color: #ffffff;
//     text-decoration: underline;
//   }

//   .footer-right {
//     font-size: 15px;
//     color: #ffffff;
//     margin-right: 12px;
//     letter-spacing: -1px;
//   }

//   .footer-right > span {
//     text-decoration: underline;
//     cursor: pointer;
//   }

//   .footer-right > span:hover {
//     color: #ffffff;
//   }
// }

// @media (max-width: $mobile) {
//   .footer {
//     .footer-right {
//       margin-top: 20px;
//     }
//   }
// }

// .footer {
//   background-color: #333;
//   color: white;
//   padding: 2rem 0;

//   .footer-container {
//     padding: 0 90px;
//     margin: 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     .footer-content {
//       display: flex;
//       justify-content: space-between;
//       flex-wrap: wrap;
//       align-items: flex-start; // Align items to the start
//     }

//     .footer-logo-section {
//       flex-shrink: 0;
//       width: 100%; // Default width for mobile (smallest)
//       max-width: 415px;
//       margin-bottom: 2rem;

//       .footer-logo {
//         height: 50px;
//         width: auto;
//       }

//       .footer-text {
//         margin-top: 1rem;
//         color: #ccc;
//       }

//       .footer-social-icons {
//         margin-top: 1rem;
//         display: flex;
//         gap: 1rem;

//         .social-icon {
//           background-color: inherit;
//           color: #dddddd;

//           &:hover {
//             color: #ca1414;
//             cursor: pointer;
//           }
//         }
//       }
//     }

//     .footer-links-section {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 2rem;
//       flex-grow: 1;
//     }

//     .footer-links-column {
//       min-width: 150px;

//       .footer-links-heading {
//         color: #bbebeb;
//         font-family: "Poppins", sans-serif;
//         font-size: 22px;
//         font-weight: 600;
//         line-height: 52px;
//         text-align: left;
//         margin-bottom: 1rem;
//       }

//       .footer-links-list {
//         list-style: none;
//         padding: 0;

//         li {
//           margin-bottom: 0.5rem;

//           div {
//             cursor: pointer;
//             color: #ffffff;

//             &:hover {
//               color: white;
//               text-decoration: underline;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.footer {
  background-color: #333;
  color: white;
  padding: 2rem 0;

  .footer-container {
    padding: 0 90px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      padding: 0 40px;
    }

    @media only screen and (max-width: 576px) {
      padding: 0 20px;
    }

    .footer-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;

      @media only screen and (max-width: 992px) {
        flex-direction: row;
        // align-items: center;
        justify-content: space-between;
      }

      @media only screen and (max-width: 576px) {
        text-align: left;
      }
    }

    .footer-logo-section {
      flex-shrink: 0;
      width: 100%;
      max-width: 415px;
      margin-bottom: 2rem;

      @media only screen and (max-width: 992px) {
        width: 40%;
      }

      @media only screen and (max-width: 576px) {
        width: 100%;
      }

      .footer-logo {
        height: 50px;
        width: auto;
      }

      .footer-text {
        margin-top: 1rem;
        color: #ccc;
      }

      .footer-social-icons {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;

        .social-icon {
          background-color: inherit;
          color: #dddddd;

          &:hover {
            color: #ca1414;
            cursor: pointer;
          }
        }

        @media only screen and (max-width: 992px) {
          justify-content: flex-start;
        }

        @media only screen and (max-width: 576px) {
          justify-content: flex-start;
        }
      }
    }

    .footer-links-section {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      @media only screen and (max-width: 992px) {
        width: 55%;
        flex-direction: row;
        justify-content: space-between;
      }

      @media only screen and (max-width: 576px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        grid-column-gap: 5rem;
      }
    }

    .footer-links-column {
      min-width: 150px;

      @media only screen and (max-width: 992px) {
        width: 45%;
      }

      @media only screen and (max-width: 576px) {
        width: 100%;
      }

      .footer-links-heading {
        color: #bbebeb;
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-weight: 600;
        line-height: 52px;
        margin-bottom: 1rem;

        @media only screen and (max-width: 992px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 576px) {
          font-size: 1rem;
          margin-bottom: 0;
        }
      }

      .footer-links-list {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 0.5rem;

          div {
            cursor: pointer;
            color: #ffffff;

            &:hover {
              color: white;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.footer-copyright {
  background-color: #dddddd;
  color: #444242;
  text-align: center;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 992px) {
  .footer-container {
    padding: 0 40px;
  }

  .footer-logo-section {
    width: 40%;
    margin-bottom: 1.5rem;
  }

  .footer-links-section {
    width: 55%;
  }
}

@media only screen and (max-width: 576px) {
  .footer-container {
    padding: 0 20px;
  }

  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo-section {
    width: 100%;
    margin-bottom: 1rem;
  }

  .footer-links-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

//for screen smaller than 394
@media only screen and (max-width: 394px) {
  .footer-container {
    padding: 0 15px;
  }

  .footer-links-section {
    grid-column-gap: clamp(0.1rem, 3vw, 3rem) !important;
    grid-template-columns: repeat(2, 1fr);
  }
}
