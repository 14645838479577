@import "helpers/_variables";

.sleeping-arrangement-container {
  .sleeping-list {
    display: flex;
    overflow: scroll;
  }

  .sleeping-list::-webkit-scrollbar {
    display: none !important;
  }

  .sleeping-type-card {
    border: 1px solid lightgray;
    padding: 12px 16px;
    min-width: 180px;
    border-radius: 8px;
  }

  .name {
    font-size: 16px;
    font-weight: bold;
  }

  .title {
    color: $heading-color;
  }

  .type {
    font-size: 14px;
    margin-bottom: 8px;
    color: $text-color;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
  }

  // reviews-tabs -----------------------------------
  .reviews-tabs-style {
    padding: 16px 0 0px;
  }

  // reviews tabs Headings
  .reviews-tabs-heading-row {
    display: flex;
    overflow-y: scroll;
    border-bottom: $divider-color;
    height: 43px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .heading-container {
    font-size: 16px;
    margin-right: 16px;
    cursor: pointer;
  }

  .active {
    color: rgb(34, 34, 34);
    animation-name: box;
    animation-duration: 1s;
  }

  .header-name {
    background-color: white;
    padding: 6px 8px;

    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .header-name:hover {
    background-color: $hover-color;
    padding: 6px 8px;
    border-radius: 8px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .header-border {
    background-color: $primary-color;
    height: 2px;
    width: 95%;
    margin: auto;
    animation-name: box-line;
    animation-duration: 1s;
  }

  .container-list>div {
    margin: 6px 0px;
  }

  .container-list span {
    font-weight: bold;
    margin-right: 8px;
    font-size: 17px;
  }

  .container-list2>div {
    margin: 6px 0px;
  }

  .container-list2 .title {
    color: $text-color;
    font-weight: bold;
    margin-right: 8px;
    font-size: 17px;
  }
}