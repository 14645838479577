.reservation-house-container {
  position: relative;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: #f7fafc;

  .background-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .content-wrapper {
    position: relative;
    display: grid;
    gap: 1.5rem;
    padding: 1rem;
    max-width: 1200px;
    width: 100%;
    z-index: 2;

    @media (min-width: 768px) {
      gap: 2rem;
    }

    .text-content {
      color: white;
      align-self: center;

      .heading {
        font-size: 0.875rem;
        color: #fc8181;
        margin-bottom: 2rem;
        font-family: "Josefin Sans", sans-serif;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 8px;
        line-height: 24px;

        @media (min-width: 768px) {
          font-size: 1rem;
          line-height: 28px;
        }

        @media (min-width: 1024px) {
          font-size: 1.25rem;
        }
      }

      .title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #ffffff;
        font-family: "Josefin Sans", sans-serif;
        line-height: 2rem;

        @media (min-width: 768px) {
          font-size: 2rem;
          line-height: 2.5rem;
        }

        @media (min-width: 1024px) {
          font-size: 2.5rem;
        }
      }

      .description {
        font-size: 0.875rem;
        margin-bottom: 1rem;
        color: #ffffff;
        font-family: "Poppins", sans-serif;

        @media (min-width: 768px) {
          font-size: 1rem;
          margin-bottom: 1.5rem;
        }

        @media (min-width: 1024px) {
          font-size: 1.125rem;
        }
      }

      h4 {
        font-family: "Poppins", sans-serif;
        color: #f7fafc;
        font-weight: bold;
      }

      p {
        font-family: "Poppins", sans-serif;

        b {
          font-weight: bold;
        }
      }
    }
  }

  // .content-wrapper {
  //   position: relative;
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   gap: 1.5rem;
  //   padding: 1rem;
  //   max-width: 1200px;
  //   width: 100%;
  //   z-index: 2;

  //   @media (min-width: 768px) {
  //     gap: 2rem;
  //   }

  //   @media (min-width: 1024px) {
  //     grid-template-columns: 2fr 1fr;
  //   }

  //   .text-content {
  //     color: white;
  //     align-self: center;

  //     .heading {
  //       font-size: 0.875rem;
  //       color: #fc8181;
  //       margin-bottom: 2rem;
  //       font-family: "Josefin Sans", sans-serif;
  //       text-decoration: underline;
  //       text-decoration-thickness: 2px;
  //       text-underline-offset: 8px;
  //       line-height: 24px;

  //       @media (min-width: 768px) {
  //         font-size: 1rem;
  //         line-height: 28px;
  //       }

  //       @media (min-width: 1024px) {
  //         font-size: 1.25rem;
  //       }
  //     }

  //     .title {
  //       font-size: 1.5rem;
  //       font-weight: 600;
  //       margin-bottom: 1rem;
  //       color: #ffffff;
  //       font-family: "Josefin Sans", sans-serif;
  //       line-height: 2rem;

  //       @media (min-width: 768px) {
  //         font-size: 2rem;
  //         line-height: 2.5rem;
  //       }

  //       @media (min-width: 1024px) {
  //         font-size: 2.5rem;
  //       }
  //     }

  //     .description {
  //       font-size: 0.875rem;
  //       margin-bottom: 1rem;
  //       color: #ffffff;
  //       font-family: "Poppins", sans-serif;

  //       @media (min-width: 768px) {
  //         font-size: 1rem;
  //         margin-bottom: 1.5rem;
  //       }

  //       @media (min-width: 1024px) {
  //         font-size: 1.125rem;
  //       }
  //     }
  //   }

  //   .offers-content {
  //     background-color: white;
  //     padding: 1.5rem;
  //     border-radius: 0.5rem;
  //     box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  //     width: 100%;
  //     height: auto;
  //     gap: 17px;

  //     @media (min-width: 768px) {
  //       width: 90;
  //     }

  //     @media (min-width: 1024px) {
  //       width: 429px;
  //       height: 513px;
  //     }

  //     .offers-list {
  //       margin-bottom: 1rem;

  //       .offer-item {
  //         margin-bottom: 1rem;

  //         .offer-title {
  //           color: #980101;
  //           font-size: 1rem;
  //           font-weight: 600;
  //           font-family: "Josefin Sans", sans-serif;

  //           @media (min-width: 768px) {
  //             font-size: 1.25rem;
  //           }

  //           @media (min-width: 1024px) {
  //             font-size: 1.5rem;
  //           }
  //         }

  //         .offer-description {
  //           font-size: 0.75rem;
  //           color: #4a5568;
  //           font-family: "Poppins", sans-serif;

  //           @media (min-width: 768px) {
  //             font-size: 0.875rem;
  //           }

  //           @media (min-width: 1024px) {
  //             font-size: 1rem;
  //           }
  //         }
  //       }
  //     }

  //     .book-now-btn {
  //       display: inline-block;
  //       width: 100%;
  //       padding: 0.75rem 0;
  //       border: 2px solid #980101;
  //       color: #980101;
  //       font-weight: 600;
  //       border-radius: 0.5rem;
  //       text-align: center;
  //       transition: all 0.3s ease;

  //       @media (min-width: 768px) {
  //         width: 100%;
  //       }

  //       &:hover {
  //         background-color: #980101;
  //         color: white;
  //       }
  //     }
  //   }
  // }
}

.loading-text {
  color: #4a5568;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }
}
