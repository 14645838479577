@import 'helpers/_variables';

.signup-container {
  .description {
    font-size: 12px;
    color: $text-color;
    padding: 4px 2px 8px 2px;
  }
}

.success-container {
  .description {
    margin: 24px;
  }

  .icon-container {
    border: 2px solid green;
    border-radius: 50%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin: auto;
  }
}

.otp-container {
  max-width: 500px;
  width: 100%;

  .error {
    color: red;
    text-align: center;
  }

  .description {
    margin-top: 12px;
    font-size: 12px;
    color: $text-color;
    padding: 4px 2px 8px 2px;
  }
}