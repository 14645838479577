.location-search-container {
  position: fixed;
  z-index: 400 !important;
  width: 100%;
  background-color: white;
  top: 0;

  .search-input-container {
    display: flex;
    align-items: center;
    position: fixed;
    background-color: white;
    width: 100%;
    padding: 16px 20px 16px;
    box-shadow: 0px 0px 2px 0px rgb(224, 209, 209);
  }
  
  .search-input-container.active {
    box-shadow: none;
    padding-bottom: 0px;
  }

  .input-container {
    border-radius: 40px;
    display: flex;
    align-items: center;
    height: 44px;
    width: 100%;
    background-color: rgb(243, 242, 242);
    box-sizing: border-box;
    border: 0.5px solid rgb(214, 212, 212);
  }

  .input-container.not-active {
    border: none;
    box-shadow: -2px 4px 12px rgba(0, 0, 0, 0.15);
    background-color: white;
  }

  .cross-icon {
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 0px 4px;
    border-radius: 50%;
    margin: 0px 8px;
  }

  .search-icon {
    margin: 0px 16px;
    margin-right: 12px;
  }

  .input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 75%;
    letter-spacing: 0.05em;
  }

  .text-style {
    margin: 0px 12px;
    font-weight: bold;
  }
}

// Location List container
.location-list-container-mobile {
  background-color: white;
  height: calc(100vh - 60px);
  overflow: auto;
  margin-top: 70px;

  .location-list-item {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    cursor: pointer;
  }

  .location-list-item:hover {
    background-color: #faf6f6;
  }

  .location-list-item-image {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin-right: 20px;
    background-color: #f6f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location-text-box {
    width: calc(100% - 50px);
  }

  .location-item-heading {
    font-weight: 16px;
    word-wrap: break-word;
  }

  .location-item-description {
    font-size: 14px;
    color: gray;
  }
}
