@import "helpers/_variables";

.location-field-container {
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text-input-container {
    padding-left: 20px;
    padding-top: 2px;
  }

  .cross-icon {
    visibility: hidden;
    background-color: rgba($color: #000000, $alpha: 0.1);
    padding: 0px 4px;
    border-radius: 50%;
  }

  .location-heading {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #bf0101;
    white-space: nowrap;
  }

  .location-input {
    border: none;
    font-size: 15px;
    width: 100%;
    min-width: 100px;
    max-width: 203px;
    outline: none;
    background-color: transparent;
    margin-left: -1px;
  }

  .reduce-width {
    border: none;
    font-size: 15px;
    width: 100%;
    min-width: 100px;
    max-width: 183px;
    outline: none;
    background-color: transparent;
    margin-left: -1px;
  }
}

.location-field-container:hover {
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.location-field-container.active {
  // transform: scale(1.01, 1.02);
  // border-radius: 8px 0 0 8px;
  // background-color: white;
  // -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  // box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.914);
}

// Location List container
.location-list-container {
  background-color: white;
  position: absolute;
  margin-top: 420px;
  border-radius: 28px;
  width: 100%;
  max-width: 500px;
  padding: 20px 0px;
  -webkit-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 24px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 30px -13px rgba(0, 0, 0, 0.75);

  .locations-item-container {
    max-height: 300px;
    min-height: 80px;
    overflow: auto;
  }

  .location-list-item {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    cursor: pointer;
  }

  .location-list-item:hover {
    background-color: #faf6f6;
  }

  .location-list-item-image {
    height: 50px;
    width: 50px;
    border-radius: 8px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    margin-right: 20px;
    background-color: #f6f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location-text-box {
    width: calc(100% - 50px);
  }

  .location-item-heading {
    font-weight: 16px;
    word-wrap: break-word;
  }

  .location-item-description {
    font-size: 14px;
    color: gray;
  }
}

@media (max-width: 600px) {
  .location-list-container {
    margin-top: 80px;
    border-radius: 20px;
    max-width: 90%;
    padding: 15px 10px;
    box-shadow: 2px 2px 20px -10px rgba(0, 0, 0, 0.7);
  }

  .location-list-item {
    padding: 8px 16px;
  }

  .location-list-item-image {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }

  .location-item-heading {
    font-size: 14px;
  }

  .location-item-description {
    font-size: 12px;
  }
}
