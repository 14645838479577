.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.modalBody {
  display: flex;
  flex-direction: column;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputField,
.textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.textarea {
  height: 80px;
  resize: none;
}

.submitBtn {
  padding: 10px;
  background: #ff0000;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #ff0000;
  }
}
