.enquiry-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enquiry-icon {
  background-color: #24d467;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #24d467;
    transform: scale(1.1);
  }
}

.enquiry-card {
  position: absolute;
  bottom: 60px;
  right: 0;
  background: rgb(238, 237, 237);
  padding: 15px;
  width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  text-align: center;

  h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }

  p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
